import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ViewportBreakpoints } from '../cssConstants';

const Container = styled.div`
  margin: 0 auto 20px auto;
  text-align: center;
  
  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    margin: 0 auto 60px auto;
    max-width: 600px;
  }
`;

const Hero = ({ children }) => (
  <Container>
    {children}
  </Container>
);

Hero.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Hero;
