import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import PostCard from './PostCard';

import { ViewportBreakpoints } from '../cssConstants';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex-flow: row wrap;
    
    margin-right: -20px;
    margin-left: -20px;
  }
`;

const PostCardContainer = ({ posts }) => (
  <Container>
    {posts.map(post => <PostCard post={post} key={post.id} />)}
  </Container>
);

PostCardContainer.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default PostCardContainer;
