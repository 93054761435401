import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Text from '@rotaready/frecl/build/Text';
import LinkWithArrow from '@rotaready/frecl/build/LinkWithArrow';

import { RowV2 } from '@rotaready/frecl/build/Row';
import { ColumnV2 } from '@rotaready/frecl/build/Column';

import CategoryList from './CategoryList';

import { ViewportBreakpoints } from '../cssConstants';

const Article = styled.article`
  margin-bottom: 20px;
  border-radius: 5px;
  border: solid 1px ${({ theme: { colors } }) => colors.grey20};
  box-shadow: 0 0 4px 1px ${({ theme: { colors } }) => colors.boxshadowmedium};
  box-sizing: border-box;
  overflow: hidden;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex: 1 0 calc(50% - 40px);
    max-width: calc(50% - 40px);
    height: 100%;
    flex-direction: column;

    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 40px;
  }
`;

const ImageContainer = styled.div``;

const DetailsContainer = styled.div`
  padding: 20px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    padding: 30px;
  }
`;

const InternalLink = styled(Link)`
  text-decoration: none;
`;

const LinkWithArrow2 = styled(LinkWithArrow)`
  span {
    flex: none;
  }
`;

const H2 = styled.h2`
  font-size: 20px;
  line-height: 28px;
  font-family: ${({ theme: { fonts } }) => fonts.H2.fontFamily};
  color: ${({ theme: { colors } }) => colors.brand160};
  font-weight: 600;
  margin: 0;

  &:hover {
    color: ${({ theme: { colors } }) => colors.brand};
  }
`;

const PostDate = styled(Text)`
  text-align: right;
`;

const PostMetadata = styled(RowV2).attrs({
  alignItems: 'center',
})`
  margin-bottom: 20px;
`;

const PostExcerpt = styled.div`
  margin: 10px 0 20px 0;
`;

const PostCard = ({ post }) => (
  <Article>
    <ImageContainer>
      {post.rotaready.featureImage
      && (
        <GatsbyImage
          image={post.rotaready.featureImage.localFile.childImageSharp.gatsbyImageData}
          alt={post.title}
          style={{ maxHeight: '240px' }}
        />
      )}
    </ImageContainer>

    <DetailsContainer>
      <PostMetadata>
        <ColumnV2>
          <CategoryList categories={post.categories.nodes} />
        </ColumnV2>
        <ColumnV2 width="120px">
          <PostDate uistyle="grey" text={post.date} size="sm" />
        </ColumnV2>
      </PostMetadata>

      <InternalLink to={`/blog/${post.slug}`}>
        <H2>{post.title}</H2>
      </InternalLink>

      <PostExcerpt>
        <Text uistyle="grey" text={post.excerpt} />
      </PostExcerpt>

      <LinkWithArrow2
        direction="right"
        to={`/blog/${post.slug}`}
        text="Read more"
        render={props => <Link {...props} />}
      />
    </DetailsContainer>
  </Article>
);

PostCard.propTypes = {
  post: PropTypes.shape({}).isRequired,
};

export default PostCard;
