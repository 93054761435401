import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';

import Text from '@rotaready/frecl/build/Text';

const Categories = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const Category = styled.li`
  display: inline-block;
  margin-right: 10px;

  p {
    color: ${({ theme: { colors } }) => colors.grey60};
  }

  a {
    text-decoration: none;
  }
`;

const CategoryList = ({ categories }) => (
  <Categories>
    {categories.map((category) => (
      <Category key={category.id}>
        <Link to={`/blog/category/${category.slug}`}>
          <Text text={category.name} size="sm" />
        </Link>
      </Category>
    ))}
  </Categories>
);

CategoryList.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default CategoryList;
