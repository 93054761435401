import he from 'he';

/* eslint-disable import/prefer-default-export */
export const normalizePost = (post) => {
  const {
    title,
    excerpt,
    rotaready: { readingTime },
  } = post;

  if (title) {
    post.title = he.decode(title);
  }

  if (excerpt) {
    // Strip out the HTML tags, decode HTML entities, then truncate and trim
    const s = he.decode(excerpt.replace(/<(?:.|\n)*?>/gm, ''));
    post.excerpt = (s.length > 220) ? `${s.substr(0, 219).trim()}...` : s;
  }

  if (!readingTime) {
    post.rotaready.readingTime = 3;
  }

  return post;
};
